import MainRouter from './component/MainRouter';
import './App.css';

function App() {
  return (
    // <div className="App">
    <div className='background'>
      <MainRouter/>
    </div>
  );
}

export default App;
